<template>
  <OptionsDialogue
    v-if="activeDialogue === 'options'"
    class="media-options-dialogue"
    :class="$attrs.class"
    :item="item"
    :type="item.mediaType"
    :title="$t('mediaOptions.title')"
    @cancel="$emit('cancel')"
  >
    <ul>
      <AssetMenuItemIcon
        v-if="canEdit"
        :type="item.mediaType"
        :label="
          isMediaNote ? $t('mediaOptions.editNote') : $t('mediaOptions.edit')
        "
        @selected.stop="toggleDialogue('edit')"
      />
      <AssetMenuItemIcon
        v-if="canRemoveColorLabel"
        :type="item.mediaType"
        :label="$t('assetTag.removeColorLabel')"
        @selected.stop="removeColorLabel"
      />
      <AssetMenuItemIcon
        v-if="canDownload"
        :type="item.mediaType"
        :label="$t('mediaOptions.download')"
        @selected.stop="download"
      />
      <AssetMenuItemIcon
        v-if="canAddComment"
        :label="$t('commentOptions.add')"
        @selected.stop="_displayComments"
      >
        <MessageIcon size="xsmall" />
      </AssetMenuItemIcon>
      <AssetMenuItemIcon
        v-if="canManageSubscription"
        :label="$t('mediaOptions.subscription')"
        @selected.stop="toggleDialogue('subscription')"
      >
        <NotificationIcon size="xsmall" />
      </AssetMenuItemIcon>
      <AssetMenuItemIcon
        :type="item.mediaType"
        :label="$t('mediaOptions.information')"
        @selected.stop="toggleDialogue('information')"
      />

      <AssetCommonOptions>
        <AssetCommonOption
          v-if="isDeleted"
          :disabled="!canRestore"
          :label="$t('options.restore')"
          @selected.stop="toggleDeletedItem"
        >
          <RestoreIcon />
        </AssetCommonOption>

        <AssetCommonOption
          :disabled="!canCopyItem"
          :label="$t('options.copy')"
          @selected.stop="addItemToClipboard('copy')"
        >
          <CopyIcon />
        </AssetCommonOption>
        <AssetCommonOption
          v-if="canMoveItem"
          :label="$t('options.move')"
          @selected.stop="addItemToClipboard('move')"
        >
          <MoveIcon />
        </AssetCommonOption>
        <AssetCommonOption
          v-if="canToggleHidden"
          :label="$t(`options.${item.hidden ? 'unhide' : 'hide'}`)"
          @selected.stop="toggleHiddenItem"
        >
          <EyeOpenIcon v-if="isHidden" />
          <EyeCloseIcon v-else />
        </AssetCommonOption>

        <AssetCommonOption
          v-if="canDelete"
          :label="$t('options.delete')"
          @selected.stop="confirmDelete"
        >
          <TrashIcon />
        </AssetCommonOption>

        <AssetCommonOption
          v-if="canDeletePermanently"
          :label="$t('options.deletePermanently')"
          @selected.stop="toggleToast('confirmDeletePermanently')"
          warning
        >
          <TrashIcon />
        </AssetCommonOption>
      </AssetCommonOptions>

      <ToastAlert
        v-if="activeToast === 'confirmDelete'"
        prompt
        level="warning"
        @ok="toggleDeletedItem"
        @cancel="toggleToast()"
      >
        {{ $t('mediaOptions.confirmDelete') }}
      </ToastAlert>
      <ToastAlert
        v-else-if="activeToast === 'confirmDeletePermanently'"
        prompt
        level="error"
        @ok="_deletePermanently"
        @cancel="toggleToast()"
      >
        <div>{{ $t('options.warningDeletePermanently') }}</div>
        <br />
        <div>{{ $t('mediaOptions.confirmDeletePermanently') }}</div>
      </ToastAlert>
    </ul>
  </OptionsDialogue>

  <InformationDialogue
    v-else-if="activeDialogue === 'information'"
    :class="$attrs.class"
    :item="item"
    @cancel="$emit('cancel')"
  />

  <component
    v-else-if="activeDialogue === 'edit' && canEdit"
    :is="editComponent"
    :class="$attrs.class"
    :editItem="item"
    @success="$emit('cancel')"
    @cancel="$emit('cancel')"
  />

  <MediaSubscriptionDialogue
    v-else-if="activeDialogue === 'subscription'"
    :class="$attrs.class"
    :asset="item"
    @success="$emit('cancel')"
    @cancel="$emit('cancel')"
  />
</template>

<script>
import { mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useMediaStore } from '@/stores/media';
import OptionsDialogue from '../dialogues/OptionsDialogue';
import InformationDialogue from '../dialogues/InformationDialogue';
import AssetMenuItemIcon from '../asset/AssetMenuItemIcon';
import MessageIcon from '../icons/Message';
import NotificationIcon from '../icons/NotificationIcon';
import CopyIcon from '../icons/Copy';
import MoveIcon from '../icons/Move';
import EyeOpenIcon from '../icons/EyeOpen';
import EyeCloseIcon from '../icons/EyeClose';
import TrashIcon from '../icons/Trash';
import RestoreIcon from '../icons/Restore';
import AssetCommonOptions from '../asset/AssetCommonOptions';
import AssetCommonOption from '../asset/AssetCommonOption';
import mediaProperties from '../mixins/mediaProperties';
import FormDialogueAudio from './FormDialogueAudio';
import FormDialogueFile from './FormDialogueFile';
import FormDialogueLink from './FormDialogueLink';
import FormDialoguePhoto from './FormDialoguePhoto';
import FormDialogueText from './FormDialogueText';
import FormDialogueVideo from './FormDialogueVideo';
import FormDialogueNote from './FormDialogueNote';
import MediaSubscriptionDialogue from './MediaSubscriptionDialogue';
import ToastAlert from '../toast/ToastAlert';
import toggleState from '../mixins/toggleState';
import selectionClipboardMixin from '../mixins/selectionClipboard';
import assetMixin from '../mixins/asset';
import commentMixin from '../mixins/comment';
import { toggleHiddenMixin } from '../mixins/assetOptionsToggleHidden';
import { toggleDeletedMixin } from '../mixins/assetOptionsToggleDeleted';
import { MEDIA_TYPES, MEDIA_TYPE } from '../../utils/types';

const formComponents = {
  audio: FormDialogueAudio,
  file: FormDialogueFile,
  link: FormDialogueLink,
  photo: FormDialoguePhoto,
  text: FormDialogueText,
  video: FormDialogueVideo,
  note: FormDialogueNote
};

export default {
  inheritAttrs: false,
  name: 'MediaOptionsDialogue',
  mixins: [
    mediaProperties,
    toggleState('dialogue', 'options'),
    selectionClipboardMixin,
    assetMixin,
    commentMixin,
    toggleHiddenMixin(MEDIA_TYPE),
    toggleDeletedMixin(MEDIA_TYPE)
  ],
  components: {
    OptionsDialogue,
    InformationDialogue,
    AssetMenuItemIcon,
    MessageIcon,
    NotificationIcon,
    CopyIcon,
    MoveIcon,
    EyeOpenIcon,
    EyeCloseIcon,
    TrashIcon,
    RestoreIcon,
    AssetCommonOptions,
    AssetCommonOption,
    MediaSubscriptionDialogue,
    ToastAlert
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    fromSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mediaTypes: [...MEDIA_TYPES]
    };
  },
  computed: {
    editComponent() {
      return formComponents[this.item.mediaType];
    },
    canEdit() {
      if (this.isReadOnly || this.isDeleted) return false;
      if (!this.isProtected) return true;
      return this.isAuthorBoard;
    },
    canAddComment() {
      return false;
      /*
      return (
        !this.isDeleted &&
        !this.isSandBox &&
        !this.isReadOnly &&
        !this.fromSearch &&
        !this.isMediaNote
      );
      */
    },
    canManageSubscription() {
      return false;
      /*
      return (
        !this.isDeleted &&
        !this.isSandBox &&
        !this.isReadOnly &&
        !this.fromSearch &&
        !this.isMediaNote
      );
      */
    },
    canDeletePermanently() {
      return (this.isDeleted && this.isAuthorBoard) || this.isMediaNote;
    },
    canRemoveColorLabel() {
      return this.canEdit && !!(this.item.tag || {}).id;
    },
    canDownload() {
      return (
        this.item.mediaType === 'photo' ||
        this.item.mediaType === 'video' ||
        this.item.mediaType === 'audio' ||
        this.item.mediaType === 'file'
      );
    }
  },
  methods: {
    ...mapActions(useAppStore, ['displayInfo', 'displayError']),
    ...mapActions(useMediaStore, [
      'update',
      'deletePermanently',
      'getDownloadSignedUrl'
    ]),
    async download() {
      const { url } = await this.getDownloadSignedUrl({
        id: this.item.id,
        share: this.isShared
      });
      window.open(url, '_blank');
    },
    addItemToClipboard(action) {
      this.addToClipboard({ item: this.item, action });
      this.$emit('mediaAddedToClipboard');
      this.$emit('cancel');
    },
    _displayComments() {
      // this.showComments();
      this.$emit('cancel');
    },
    async _deletePermanently() {
      try {
        this.toggleToast();
        await this.deletePermanently(this.item);
        this.$emit('cancel');
      } catch (e) {
        console.error('MediaOptionsDialogue => _deletePermanently', e);
        this.displayError({ message: this.$t('global.error') });
      }
    },
    async removeColorLabel() {
      try {
        this.update({
          id: this.item.id,
          data: { assetTagId: null }
        });

        this.$emit('cancel');
      } catch (e) {
        console.error('MediaOptionsDialogue => removeColorLabel', e);
        this.displayError({ message: this.$t('global.error') });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.media-options-dialogue__media-boxes {
  @include flexy();
  margin-bottom: $spacing-base;

  > .media-box {
    margin-right: rem(3px);
  }
}
</style>
